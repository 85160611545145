import { getUploadSign } from '@/api/oss'
// import request from '@/plugins/axios'
import axios from 'axios'
import { Message } from 'element-ui'

export function uploadFileForUrl(url, file) {
  return axios({
    method: 'post',
    url: url,
    data: file
  })
}

export async function myUpload(uploadFile, remark) {
  const {
    access_key_id: AccessKeyId,
    access_key_secret: AccessKeySecret,
    secret_token: SecurityToken,
    user_id_encrypt: userIdEncrypt
  } = await getUploadSign()
  console.log('签名', AccessKeyId, AccessKeySecret, SecurityToken)
  const OSS = require('ali-oss')
  const client = new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    // region: 'oss-cn-beijing',
    endpoint: 'https://oss-cn-beijing.aliyuncs.com',
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: AccessKeyId,
    // accessKeyId: 'STS.NTyfiHXDBNo7vcnmkWCe5p2tT',
    accessKeySecret: AccessKeySecret,
    // accessKeySecret: 'F99WuGeVadAuSKXqf27qE6UH7dzQXqm5w7PKzL5Nc38k',
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: SecurityToken,
    // stsToken: 'CAISmAJ1q6Ft5B2yfSjIr5fMLdP8tZtj+a3cdEXfiWsCT+oZn/fftjz2IH1JdXBpBe8Xsfo2mGFX5vYalq1vRoRZHa8clDSnqcY5yxioRqackcjXhOV2h//IMGyXDAGBk622Su7lTdTbV+6wYlTf7EFayqf7cjPQOD7ANoaS26Z6cvMdXASzcUAbZrFsKxBltdUROFbIKP+pKWSKuGfLC1dysQcO+AEA4K+kkMqH8Uic3h+ow/IJoMHpJYO4KItORM4uAo7ohLMoKPGZjX4IsUJwmf0o3PAey1DupcqABlRd2RKCPtfP/8dnRA0DPfVnSvcd8KetyqMh5r2Mx9Sv0W1EIP1SFjzYX5zlx9DfXfyzN8o0ZTh7mIplUnzkGoABs08SQF42khYGSE6uIyWYLHylPYmP7EVyMj3eQPb/zx4tZBRaglwwuvIbaWePCf0AVqOoOTfWPbZYXyfDOjn0mVMxiPhLD66vqMD71rhKRI8P7qY7RvMjl637yBjoMyZrHD0CBInr+ghyVTTi5CRTQd2TvVxtVVPZA9UHr9bUNpM=',
    // 填写Bucket名称。
    bucket: 'auto-post',
    cname: false
  })
  // 从输入框获取file对象，例如<input type="file" id="file" />。
  // const data = document.getElementById('file').files[0]
  // 创建并填写Blob数据。
  console.log(uploadFile)
  // const fileData = new Blob(fileForm.file)
  // 创建并填写OSS Buffer内容。
  // const data = new OSS.Buffer('Hello OSS');
  const fileType = uploadFile.name.split('.').pop()

  console.log('userIdEncrypt', userIdEncrypt)
  const callback = {
    // 设置回调请求的服务器地址。
    url: process.env.VUE_APP_OSS_CALLBACK_URL,
    // 设置回调请求消息头中Host的值，即您的服务器配置的Host值。
    // host: 'oss-cn-hangzhou.aliyuncs.com',
    // 设置发起回调时请求body的值。
    body:
      'bucket=${bucket}&object=${object}&etag=${etag}&size=${size}&mimeType=${mimeType}&imageInfo.height=${imageInfo.height}&imageInfo.width=${imageInfo.width}&imageInfo.format=${imageInfo.format}&userIdEncrypt=${x:userIdEncrypt}&remark=${x:remark}&name=${x:name}', // eslint-disable-line no-template-curly-in-string
    // 设置发起回调请求的Content-Type。
    // contentType: 'application/x-www-form-urlencoded',
    // 设置发起回调请求的自定义参数。
    customValue: {
      userIdEncrypt: userIdEncrypt,
      remark: remark,
      name: uploadFile.name
    }
  }

  let fullPath = ''
  if (process.env.NODE_ENV === 'production') {
    const date = new Date()
    const year = date.getFullYear()
    const month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const timeStr = `${year}-${month}-${day}`
    fullPath = `upload/${timeStr}/${uploadFile.uid}.${fileType}`
  } else {
    fullPath = `upload/development/${uploadFile.uid}.${fileType}`
  }

  try {
    // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
    // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
    // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
    const result = await client.put(fullPath, uploadFile.raw, {
      timeout: 600000,
      callback: callback
    })
    console.log('上传结果', result)
    return result
  } catch (e) {
    Message.error('文件上传失败')
    return null
  }
}
